.modal-policy {
  background-color: fade-out(black, .2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;

  .wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 825px;
    padding: 70px 80px;

    /** Mobile. */
    @media (max-width: 1023px) {
      width: 100%;
      height: 100vh;
      padding: 40px 35px;
    }

    &-close {
      position: absolute;
      top: 25px;
      right: 25px;

      svg path {
        fill: #eeaa37;
      }
    }

    &-title {
      color: #eeaa37;
      font-size: 18px;
      font-weight: bold;
      text-align: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        font-size: 24px;
      }
    }

    &-description {
      color: black;
      margin: 45px 0 0;
      padding-right: 20px;
      max-height: 400px;
      overflow: auto;

      > * {
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      a {
        color: #b79b74;
        font-weight: bold;
      }
      
      p {
        font-size: 16px;
        font-weight: normal;
        line-height: 1.7;
      }

      h3 {
        font-size: 20px;
        font-weight: bold;
        margin-top: 40px;
      }

      ul {
        position: relative;
        width: 100%;

        li {
          font-size: 16px;
          font-weight: normal;
          line-height: 1.7;
          position: relative;
          width: 100%;
          padding-left: 15px;

          &::before {
            content: '';
            background-color: black;
            position: absolute;
            top: 9px;
            left: 0;
            width: 5px;
            height: 5px;
            border-radius: 50%;
          }
        }
      }

      /** Mobile. */
      @media (max-width: 1023px) {
        max-height: 70vh;
      }

      &::-webkit-scrollbar {
        width: 5px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 3px;
        }
      }

      &::-webkit-scrollbar-track {
        background: fade-out(black, .9);
      }

      &::-webkit-scrollbar-thumb {
        background: #b79b74;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #b79b74;
      }
    }
  }
}