.modal-success {
  background-color: fade-out(black, .3);
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    background-color: white;
    position: relative;
    width: 700px;
    padding: 70px 0;

    /** Mobile. */
    @media (max-width: 768px) {
      width: 90vw;
    }

    &-description {
      text-align: center;
      padding: 0 30px;

      /** Mobile. */
      @media (max-width: 767px) {
        width: 100%;
        padding: 0 25px;
      }

      p {
        color: #808080;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.7;

        /** Mobile. */
        @media (max-width: 768px) {
          br {
            display: none;
          }
        }

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }

    &-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;

      /** Mobile. */
      @media (max-width: 768px) {
        flex-direction: column;
      }

      a {
        background-color: #eeaa37;
        color: white;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        height: 50px;
        box-shadow: 0px 20px 40px 0px fade-out(#eeaa37, .47);
        transition-property: background-color, color;
        transition-duration: time(default);
        transition-timing-function: ease(inout);

        &:hover {
          background-color: white;
          color: #eeaa37;
        }

        &:not(:last-child) {
          margin-right: 15px;

          /** Mobile. */
          @media (max-width: 768px) {
            margin: 0 0 35px;
          }
        }
      }
    }
  }
}