.modal-cta {
  background-color: white;

  .close {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 3;

    svg path {
      fill: black;
    }
  }

  .wrapper {
    position: absolute;
    top: 0;
    right: 25px;
    bottom: 0;
    left: 25px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &-title {
      color: black;
      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      line-height: 1.3;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 90px;
    }

    &-form {
      width: 100%;
      max-width: 300px;
      margin-top: 30px;

      .form {
        padding: 0;

        .field-group {
          &:not(:last-child) {
            margin-bottom: 25px;
          }

          .label {
            color: #262e32;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 7px;
          }

          .field {
            background-color: transparent;
            color: #262e32;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 10px;
            border: none;
            border-bottom: 1px solid #b2b2b2;
            width: 100%;
            display: block;
            outline: none;
            transition-property: border-color;
            transition-duration: time(default);
            transition-timing-function: ease(inout);

            &::placeholder {
              color: #b2b2b2;
            }
          }

          &.invalid .field {
            border-color: red;
          }
        }

        .terms-option {
          margin-bottom: 20px;

          button {
            background-color: transparent;
            padding-left: 25px;
            border: none;
            position: relative;

            &::before {
              content: '';
              position: absolute;
              top: 1px;
              left: 0;
              width: 12px;
              height: 12px;
              border: 1px solid black;
              transition-property: background-color, border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);
              z-index: 1;
            }

            span {
              color: black;
              font-size: 9px;
              text-align: left;
              line-height: 1.4;
              font-weight: normal;
              display: inline-block;

              a {
                color: black;
                font-weight: bold;
              }
            }
          }

          &.active button::before {
            background-color: #eeaa37;
            border-color: #eeaa37;
          }
        }

        .form-submit {
          position: relative;
          width: 100%;

          button {
            background-color: #eeaa37;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            color: white;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: -.5px;
            width: 100%;
            height: 50px;
            box-shadow: 0px 20px 40px 0px rgba(93, 78, 61, 0.53);
          }
        }
      }
    }
  }
}