#page-home {
  position: relative;
  width: 100%;
  overflow: hidden;

  &.full {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .mobile-ctas {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    height: 60px;
    z-index: 45;

    /** Desktop. */
    @media (min-width: 1200px) {
      display: none;
    }

    .cta {
      position: relative;
      height: 100%;

      a,
      button {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;
        height: 100%;
      }
      
      &:nth-child(1) {
        background-color: #eeaa37;
        flex-grow: 1;

        button {
          color: white;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
          letter-spacing: 2px;
        }
      }

      &:nth-child(2) {
        background-color: #32b17a;
        width: 60px;
      }
    }
  }

  #main-whatsapp {
    position: fixed;
    top: 50px;
    right: 35px;
    z-index: 45;

    @keyframes whatsapp_pulse {
      0% {
        transform: scale(.9) translate(-50%, -50%);
        opacity: .5;
      }
      100% {
        transform: scale(2) translate(-50%, -50%);
        opacity: 0;
      }
    }

    &::before,
    &::after {
      content: '';
      background-color: #32b17a;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      transform-origin: 0% 0%;
      transform: scale(.9) translate(-50%, -50%);
      animation-name: whatsapp_pulse;
      animation-duration: 3s;
      animation-iteration-count: infinite;
      animation-timing-function: ease(inout);
      z-index: 1;
    }

    &::after {
      animation-delay: 1.5s;
    }

    a {
      background-color: #32b17a;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 60px;
      height: 60px;
      z-index: 2;
      border-radius: 50%;
    }

    /** Mobile. */
    @media (max-width: 1200px) {
      display: none;
    }
  }

  .page-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 1790px;
    z-index: 1;

    /** Mobile. */
    @media (max-width: 1023px) {
      background-color: black;
      height: 1320px;

      img {
        opacity: 0.6;
      }
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .section {
    position: relative;
    z-index: 2;
  }

  .section-hero {
    width: 100%;
    height: 778px;

    /** Mobile. */
    @media (max-width: 1023px) {
      height: 620px;
    }

    .logo {
      position: absolute;
      top: 30px;
      left: 40px; 

      /** Mobile. */
      @media (max-width: 1023px) {
        top: 10px;
        left: 50%;
        transform: translateX(-50%);

        img {
          width: 150px;
        }
      }
    }

    .object {
      position: absolute;
      pointer-events: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      &-1 {
        top: 30px;
        left: calc(50% + 190px);
        z-index: 5;
      }

      &-2 {
        bottom: 10px;
        left: calc(50% + 520px);
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .flex-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      height: 778px;
      padding-left: 100px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: 620px;
        padding: 0;
      }

      .left {
        padding-bottom: 170px;

        @media (max-width: 1023px) {
          padding-bottom: 100px;
        }

        &-title {
          color: white;
          font-size: 30px;
          font-weight: 500;
          line-height: 1.2;
          margin-bottom: 25px;

          /** Mobile. */
          @media (max-width: 1023px) {
            font-size: 21px;
          }
        }

        &-features {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-bottom: 20px;

          .item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
              margin-bottom: 10px;
            }

            &-icon {
              flex-shrink: 0;
              margin-right: 25px;

              /** Mobile. */
              @media (max-width: 1023px) {
                margin-right: 15px;
              }
            }

            &-title {
              color: white;
              font-size: 16px;
              font-weight: bold;
            }
          }
        }

        &-selo {
          position: relative;
          display: inline-block;
          display: none;

          &::before {
            content: '';
            background-color: #ff451d;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            height: 20px;
            width: 50vw;
          }

          img {
            position: relative;
            z-index: 2;
            pointer-events: none;
            user-select: none;

            /** Mobile. */
            @media (max-width: 1023px) {
              width: 150px;
            }
          }
        }
      }

      .right {
        position: relative;
        width: 340px;
        padding-bottom: 210px;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }

        &-title {
          background-color: white;
          color: #eda936;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 2px;
          line-height: 1.4;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 75px;
        }

        &-form {
          background-color: fade-out(white, .2);
          position: relative;
          width: 100%;
          padding: 35px;

          .field-group {
            &:not(:last-child) {
              margin-bottom: 25px;
            }

            .label {
              color: #262e32;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 7px;
            }

            .field {
              background-color: transparent;
              color: #262e32;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px;
              border: none;
              border-bottom: 1px solid #b2b2b2;
              width: 100%;
              display: block;
              outline: none;
              transition-property: border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &::placeholder {
                color: #b2b2b2;
              }
            }

            &.invalid .field {
              border-color: red;
            }
          }

          .terms-option {
            margin-bottom: 20px;

            button {
              background-color: transparent;
              padding-left: 25px;
              border: none;
              position: relative;

              &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid black;
                transition-property: background-color, border-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
                z-index: 1;
              }

              span {
                color: black;
                font-size: 9px;
                text-align: left;
                line-height: 1.4;
                font-weight: normal;
                display: inline-block;

                a {
                  color: black;
                  font-weight: bold;
                }
              }
            }

            &.active button::before {
              background-color: #eeaa37;
              border-color: #eeaa37;
            }
          }

          .form-submit {
            position: relative;
            width: 100%;

            button {
              background-color: #eeaa37;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              color: white;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: -.5px;
              width: 100%;
              height: 50px;
              box-shadow: 0px 20px 40px 0px rgba(93, 78, 61, 0.53);
            }
          }
        }
      }
    }
  }

  .section-about {
    overflow: hidden;

    .object {
      position: absolute;
      pointer-events: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      &-1 {
        top: 30px;
        left: calc(50% - 600px);
        transform: translateX(-50%);
        z-index: 5;
      }

      &-2 {
        bottom: 380px;
        left: calc(50% + 440px);
        z-index: 2;
      }
    }

    .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 1012px;
      padding-bottom: 100px;

      /** Mobile. */
      @media (max-width: 1023px) {
        justify-content: flex-start;
        width: 100%;
        height: 700px;
        padding: 80px 0 0;
      }

      &-image {
        position: relative;
        z-index: 1;
        pointer-events: none;
        user-select: none;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }
      }

      &-title {
        color: white;
        font-size: 23px;
        font-weight: bold;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: .5px;
        line-height: 1.4;
        position: absolute;
        right: 120px;
        top: 230px;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 21px;
          position: relative;
          top: unset;
          right: unset;

          br {
            display: none;
          }
        }
      }

      &-description {
        position: absolute;
        right: 155px;
        top: 340px;
        width: 470px;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          position: relative;
          top: unset;
          right: unset;
          width: 100%;
          margin: 30px 0 0;
        }

        p {
          color: black;
          font-size: 14px;
          font-weight: 500;
          line-height: 1.7;

          /** Mobile. */
          @media (max-width: 1023px) {
            color: white;
          }

          &:not(:last-child) {
            margin-bottom: 25px;
          }
        }
      }
    }
  }

  .section-gallery {
    z-index: 3;
    margin-top: -150px;
    padding-bottom: 90px;

    /** Mobile. */
    @media (max-width: 1023px) {
      margin-top: -80px;
    }

    .slide {
      position: relative;
      height: 800px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: auto;
      }

      /** Appear. */
      @media (min-width: 1200px) {
        opacity: 0;
        transition-property: opacity;
        transition-duration: 1.5s;
        transition-timing-function: ease(inout);

        &.appear {
          opacity: 1;
        }
      }

      &-arrows {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 970px;
        height: 650px;
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }

        .slide-arrow {
          background-color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 60px;

          svg path {
            stroke: #9f8b7a;
          }
        }
      }

      &-items {
        position: relative;
        width: 100%;
        z-index: 1;

        .item {
          position: relative;
          width: 970px;
          margin: 0 auto;
          padding: 60px 0 90px;

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 100%;
            padding: 0;
          }

          &-image {
            position: relative;
            width: 100%;
            height: 650px;
            box-shadow: 0px 40px 80px 0px rgba(36, 25, 11, 0.5);
            z-index: 1;

            /** Mobile. */
            @media (max-width: 1023px) {
              height: 450px;
              box-shadow: unset;
            }
            @media (max-width: 767px) {
              height: 220px;
            }
            @media (max-width: 355px) {
              height: 180px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &-title {
            color: #261a0f;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase;
            letter-spacing: 2px;
            display: block;
            text-align: center;
            position: relative;
            z-index: 2;
            margin: 35px 0 0;

            /** Mobile. */
            @media (max-width: 1023px) {
              letter-spacing: unset;
              line-height: 1.2;
            }
          }
        }
      }

      &-dots {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -60px;
        z-index: 5;

        /** Mobile. */
        @media (max-width: 1023px) {
          margin: 10px 0 0;
        }

        .owl-dot {
          background-color: fade-out(#261a0f, .7);
          position: relative;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          cursor: pointer;
          transition-property: background-color;
          transition-duration: time(default);
          transition-timing-function: ease(inout);

          /** Mobile. */
          @media (max-width: 1023px) {
            width: 4px;
            height: 4px;
          }

          &:not(:last-child) {
            margin-right: 10px;

            /** Mobile. */
            @media (max-width: 1023px) {
              margin-right: 4px;
            }
          }

          &.active,
          &:hover {
            background-color: #261a0f;
          }
        }
      }
    }
  }

  .section-features {
    .object {
      position: absolute;
      pointer-events: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      &-1 {
        top: -95px;
        left: calc(50% + 380px);
        z-index: 5;
      }
    }

    .background {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      /** Mobile. */
      @media (max-width: 1023px) {
        background-color: black;

        img {
          opacity: 0.6;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
      height: 1150px;
      padding-bottom: 95px;

      /** Mobile. */
      @media (max-width: 1023px) {
        height: auto;
        padding: 80px 0;
      }

      &-title {
        color: white;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.2;
        padding: 0 50px;
        margin-bottom: 90px;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 21px;
          padding: 0;
          margin-bottom: 40px;
        }
      }

      &-items {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 25px;

        /** Mobile. */
        @media (max-width: 1023px) {
          grid-template-columns: 1fr 1fr;
        }

        .item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;
          height: 140px;
          border: 1px solid white;

          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 65px;
            position: relative;
            padding-bottom: 5px;
            margin-bottom: 5px;

            img {
              max-width: 45px;
              max-height: 45px;
            }

            &::after {
              content: '';
              background-color: #936e4d;
              position: absolute;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              width: 50px;
              height: 1px;
            }
          }

          &-title {
            color: white;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.2;
            padding: 0 5px;
            text-align: center;
          }
        }
      }
    }
  }

  .section-map {
    background-color: #eef0e5;
    padding-bottom: 45px;

    .map {
      position: relative;
      width: 100%;
      height: auto;
      pointer-events: none;
      user-select: none;

      img {
        width: 100%;
        display: block;
      }
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 20px 0 0;

      &-title {
        color: #373636;
        font-size: 30px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 30px;
        position: relative;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 18px;
          letter-spacing: unset;
          text-align: center;
          margin-bottom: 15px;
        }

        &::before {
          right: calc(100% + 35px);
        }

        &::after {
          left: calc(100% + 35px);
        }

        &::before,
        &::after {
          content: '';
          background-color: #e94219;
          position: absolute;
          top: 15px;
          width: 50vw;
          height: 2px;

          /** Mobile. */
          @media (max-width: 767px) {
            display: none;
          }
        }
      }

      &-description {
        color: #373636;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  .section-launch {
    background-color: #eef0e5;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 50px 0;
    }

    .headline {
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
	  display: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        align-items: center;
      }

      &-image {
        position: relative;
        pointer-events: none;
        user-select: none;
        z-index: 1;

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }
      }

      &-title {
        color: #f4c24a;
        font-size: 30px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
        position: absolute;
        top: 110px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          font-size: 21px;
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          text-align: center;
        }
      }

      &-description {
        color: white;
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;

        /** Mobile. */
        @media (max-width: 1023px) {
          color: black;
          position: relative;
          top: unset;
          left: unset;
          transform: unset;
          text-align: center;
          margin-top: 25px;
          line-height: 1.4;
        }
      }
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        margin-top: 40px;
      }

      .left {
        width: 335px;
        flex-shrink: 0;
		display: none;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          margin: 0 0 40px;
        }

        &-description {
          p {
            color: #333333;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.7;
          }
        }

        &-text {
          color: #333333;
          font-size: 9px;
          font-weight: 500;
          line-height: 1.4;
          margin-top: 25px;
        }
      }

      .right {
        width: 700px;
        flex-shrink: 0;
        margin-left: 40px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          margin: 0;
        }

        .slide {
          position: relative;
          width: 100%;
          height: 560px;

          /** Mobile. */
          @media (max-width: 1023px) {
            height: auto;
          }

          /** Appear. */
          @media (min-width: 1200px) {
            opacity: 0;
            transition-property: opacity;
            transition-duration: 1.5s;
            transition-timing-function: ease(inout);

            &.appear {
              opacity: 1;
            }
          }

          &-arrows {
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            height: 470px;
            z-index: 2;

            /** Mobile. */
            @media (max-width: 1023px) {
              display: none;
            }

            .slide-arrow {
              background-color: white;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 50px;
              height: 50px;

              svg path {
                stroke: #9f8b7a;
              }
            }
          }

          &-items {
            position: relative;
            width: 100%;
            z-index: 1;

            .item {
              position: relative;
              width: 100%;

              &-image {
                position: relative;
                width: 100%;
                height: 470px;
                z-index: 1;

                /** Mobile. */
                @media (max-width: 1023px) {
                  height: 450px;
                }
                @media (max-width: 767px) {
                  height: 220px;
                }
                @media (max-width: 355px) {
                  height: 180px;
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }

              &-title {
                color: #261a0f;
                font-size: 12px;
                font-weight: bold;
                display: block;
                text-align: center;
                position: relative;
                z-index: 2;
                margin: 25px 0 0;
              }
            }
          }

          &-dots {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            z-index: 5;

            .owl-dot {
              background-color: fade-out(#261a0f, .7);
              position: relative;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              cursor: pointer;
              transition-property: background-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              /** Mobile. */
              @media (max-width: 1023px) {
                width: 4px;
                height: 4px;
              }

              &:not(:last-child) {
                margin-right: 10px;

                /** Mobile. */
                @media (max-width: 1023px) {
                  margin-right: 4px;
                }
              }

              &.active,
              &:hover {
                background-color: #261a0f;
              }
            }
          }
        }
      }
    }
  }

  .section-status {
    width: 100%;
    padding: 70px 0;
	background-color: #eef0e5;
    
    .container {
      height: 100%;
    }

    .flex-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		margin: 20px 0 0;
  
		&-title {
		  color: #373636;
		  font-size: 30px;
		  font-weight: 500;
		  text-transform: uppercase;
		  letter-spacing: 1px;
		  margin-bottom: 30px;
		  position: relative;
  
		  /** Mobile. */
		  @media (max-width: 1023px) {
			font-size: 18px;
			letter-spacing: unset;
			text-align: center;
			margin-bottom: 15px;
		  }
  
		  &::before {
			right: calc(100% + 35px);
		  }
  
		  &::after {
			left: calc(100% + 35px);
		  }
  
		  &::before,
		  &::after {
			content: '';
			background-color: #e94219;
			position: absolute;
			top: 15px;
			width: 50vw;
			height: 2px;
  
			/** Mobile. */
			@media (max-width: 767px) {
			  display: none;
			}
		  }
		}
  
		&-description {
		  color: #373636;
		  font-size: 16px;
		  font-weight: bold;
		}
	  }
  
      .title {
        font-size: 68px;
        color: #333;
        font-weight: 700;
        text-align: center;
        position: relative;
        
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          height: 1.5px;
          background-color: #daab93;
          bottom: -18px;
        }
  
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          height: 1.5px;
          background-color: #daab93;
          bottom: -24px;
        }
  
        @media (max-width: 768px) {
          font-size: 32px
        }
      }

      .status-grid {
        width: 100%;
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px 20px;
        padding: 0 160px;
        margin-top: 70px;
        position: relative;

        @media (max-width: 768px) {
          padding: 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &:before {
          content: '';
          position: absolute;
          left: 51%;
          transform: translateX(-50%);
          width: 70%;
          height: 1px;
          background-color: rgb(51, 51, 51);
          bottom: -40px;
        }

        .status {
          display: flex;
          align-items: center;
          width: 100%;

          @media (max-width: 768px) {
            padding-right: 8vw;
          }

          .icon {
            width: 40px;
            flex-shrink: 0;
            display: flex;
            justify-content: center;
          }

          &:nth-child(1) {
            @media (max-width: 768px) {
              order: 1;
            }

            .status-bar {
              width: 90%;
            }
          }

          &:nth-child(2) {
            @media (max-width: 768px) {
              order: 3;
            }

            .status-bar {
              width: 90%;
            }
          }

          &:nth-child(3) {
            @media (max-width: 768px) {
              order: 2;
            }

            .status-bar {
              width: 80%;
            }
          }

          &:nth-child(4) {
            @media (max-width: 768px) {
              order: 4;
            }

            .status-bar {
              width: 10%;
            }
          }
        }

        .status-bar-wrapper {
          display: flex;
          flex-direction: column;
          margin-left: 6px;
          width: 100%;

          .status-title {
            font-size: 12px;
            color: #333;
            font-weight: bold;
            text-transform: uppercase;
          }

          .bar-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;

            .percentage {
              font-size: 15px;
              color: #eda936;
              font-weight: bold;
              text-transform: uppercase;
              margin-right: 6px;
              width: 38px;
              flex-shrink: 0;
            }

            .bar {
              border: 1px solid rgb(51, 51, 51);
              height: 9px;
              position: relative;
              flex-grow: 1;

              @media (max-width: 768px) {
                width: 200px;
              }
            }

            .status-bar {
              position: absolute;
              left: 0;
              bottom: 0;
              height: 100%;
              background-color: #eda936;
            }
          }
        }
      }

      .global {
        margin-top: 80px;

        &-title {
          font-size: 13px;
          color: rgb(43, 92, 104);
          text-transform: uppercase;
          text-align: center;
          margin-bottom: 30px;
          letter-spacing: 1.5px;
        }

        .bar-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;

          .percentage {
            font-size: 15px;
            color: #eda936;
            font-weight: bold;
            text-transform: uppercase;
            margin-right: 6px;
          }
        }

        &-bar {
          border: 1px solid rgb(51, 51, 51);
          position: relative;
          width: 795px;
          height: 10px;

          @media (max-width: 768px) {
            width: 80vw;
          }

          &__status {
            position: absolute;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 67.5%;
            background-color: #eda936;
          }
        }
      }
    }
  }

  .section-gallery-status {
    position: relative;
    width: 100%;
    padding: 10px 0;
	background-color: #eef0e5;
	padding: 40px 0 80px 0;

    .gallery {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .title-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 70px 0 -40px 0;
      }

      .title {
        font-size: 48px;
        font-family: "Montserrat";
        color: rgb(8, 117, 130);
        font-weight: 400;
        text-transform: uppercase;
        line-height: 1.2;

        @media (max-width: 768px) {
          font-size: 32px;
          text-align: center;
        }
      }

      .headline {
        color: #e47016;
        line-height: 1;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;

        @supports (-webkit-background-clip: text) {
          color: transparent;
          background: -webkit-linear-gradient(left, #eec124, #e47016);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media (max-width: 768px) {
          font-size: 16px;
          margin-top: 60px;
          padding: 0 60px;
        }

        h2 {
          line-height: 1.2;
          font-weight: 800;
        }

        strong {
          font-weight: 100;
        }
      }

      .subtitle {
        font-size: 16px;
        color: rgb(2, 80, 109);
        line-height: 1.2;
        text-align: center;
        margin-top: 20px;

        @media (max-width: 768px) {
          padding: 0 40px;
          margin-top: 30px;
        }
      }

      .gallery {
        position: relative;

        @media (max-width: 768px) {
          margin-top: 0;
        }

        .item {
		  position: relative;
		  margin: 0 25px;
		  width: 250px;
		  height: 220px;
		  overflow: hidden;

		  @media (max-width: 768px) {
			  margin: 0 10vw;
			  width: 67vw;
		  }

		  @media screen and (device-aspect-ratio: 40/71) {
			  height: 200px;
		  }

		  &:before {
			  content: '';
			  position: absolute;
			  width: 100%;
			  height: 100px;
			  top: 0;
			  left: 0;
			  z-index: 1;
			  background-image: linear-gradient(to bottom, rgba(0, 0, 0, .5), transparent);
		  }

          @media (max-width: 768px) {
            padding: 0 0 60px 0;
          }

          .content {
            width: 100%;
            max-width: 610px;
            margin: 0 auto;
          }

          &__row-zoom {
            display: flex;
            justify-content: flex-end;
            position: relative;
            right: 10px;
			top: 15px;
			z-index: 2;

            a {
              font-size: 14px;
              color: black;
              line-height: 1.2;
              display: flex;
              align-items: center;

              @media (max-width: 768px) {
                transform: scale(0.7);
              }

              span {
                margin-right: 10px;
                transition: transform 350ms ease;

                &:hover {
                  transform: scale(1.1);
                }
              }
            }
          }

          &__row-image {
            display: flex;
            justify-content: center;
			position: absolute;
			top: 0;

            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }

			.description {
				background-color: #eda936;
				position: absolute;
				bottom: 0;
				width: 100%;
				text-align: center;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;

				svg {
					transform: scale(1.4);
				}

				&-text {
					color: white;
					margin-left: 20px;
				}
			}
        }

        &__navigation {
          display: flex;
          align-items: center;
          position: absolute;
          width: 100%;
          top: 50%;
		  transform: translateY(-50%);
          z-index: 2;

          @media (max-width: 768px) {
            position: static;
            justify-content: center;
            margin-bottom: 30px;
          }

          .arrow {
            opacity: 1;
            transition: 250ms;

            &:hover {
              opacity: 0.6;
            }

            &__prev {
              position: absolute;
              left: -10px;

              @media (max-width: 768px) {
                bottom: 100px;
              }
            }

            &__next {
              position: absolute;
              right: -10px;

              @media (max-width: 768px) {
                bottom: 100px;
              }
            }
          }
        }
      }
    }
  }

  .section-cta {
    margin-top: -50px;
    z-index: 1;

    /** Mobile. */
    @media (max-width: 1023px) {
      display: none;
    }

    .background {
      background-position: top center;
      background-size: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }

    .container {
      position: relative;
      z-index: 2;
    }

    .wrapper {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      justify-content: flex-end;
      height: 920px;
      padding-left: 100px;
      padding-bottom: 135px;

      .left {
        position: relative;
        width: 340px;
        box-shadow: 0px 43px 59px 0px rgba(0, 0, 0, 0.31);

        /** Mobile. */
        @media (max-width: 1023px) {
          display: none;
        }

        &-title {
          background-color: white;
          color: #eda936;
          font-size: 16px;
          font-weight: bold;
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 2px;
          line-height: 1.4;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 75px;
        }

        &-form {
          background-color: fade-out(white, .2);
          position: relative;
          width: 100%;
          padding: 35px;

          .field-group {
            &:not(:last-child) {
              margin-bottom: 25px;
            }

            .label {
              color: #262e32;
              font-size: 14px;
              font-weight: 500;
              margin-bottom: 7px;
            }

            .field {
              background-color: transparent;
              color: #262e32;
              font-size: 14px;
              font-weight: 500;
              padding-bottom: 10px;
              border: none;
              border-bottom: 1px solid #b2b2b2;
              width: 100%;
              display: block;
              outline: none;
              transition-property: border-color;
              transition-duration: time(default);
              transition-timing-function: ease(inout);

              &::placeholder {
                color: #b2b2b2;
              }
            }

            &.invalid .field {
              border-color: red;
            }
          }

          .terms-option {
            margin-bottom: 20px;

            button {
              background-color: transparent;
              padding-left: 25px;
              border: none;
              position: relative;

              &::before {
                content: '';
                position: absolute;
                top: 1px;
                left: 0;
                width: 12px;
                height: 12px;
                border: 1px solid black;
                transition-property: background-color, border-color;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
                z-index: 1;
              }

              span {
                color: black;
                font-size: 9px;
                text-align: left;
                line-height: 1.4;
                font-weight: normal;
                display: inline-block;

                a {
                  color: black;
                  font-weight: bold;
                }
              }
            }

            &.active button::before {
              background-color: #eeaa37;
              border-color: #eeaa37;
            }
          }

          .form-submit {
            position: relative;
            width: 100%;

            button {
              background-color: #eeaa37;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              color: white;
              font-size: 12px;
              font-weight: bold;
              text-transform: uppercase;
              letter-spacing: -.5px;
              width: 100%;
              height: 50px;
              box-shadow: 0px 20px 40px 0px rgba(93, 78, 61, 0.53);
            }
          }
        }
      }
    }
  }

  .section-footer {
    background-color: #eef0e5;
    padding: 50px 0 35px;

    /** Mobile. */
    @media (max-width: 1023px) {
      padding: 50px 0 100px;
    }

    .flex-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      padding: 0 100px;

      /** Mobile. */
      @media (max-width: 1023px) {
        flex-direction: column;
        justify-content: center;
        padding: 0;
      }

      &:last-child {
        margin-top: 80px;

        /** Mobile. */
        @media (max-width: 1023px) {
          margin-top: 50px;
        }
      }

      .left {
        flex-shrink: 0;
      }

      .right {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-row-gap: 80px;
        width: 670px;

        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
          grid-template-columns: 1fr;
          grid-row-gap: 60px;
          margin: 50px 0 0;
        }

        .item {
          &-title {
            color: #261a0f;
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 1px;
            width: 180px;
            border-bottom: 1px solid #eeaa37;
            padding-bottom: 4px;

            /** Mobile. */
            @media (max-width: 1023px) {
              width: 100%;
            }
          }

          &-description {
            color: #261a0f;
            font-size: 14px;
            font-weight: 500;
            line-height: 1.7;
            margin-top: 20px;
          }

          &-phone {
            margin-top: 20px;

            a {
              color: #261a0f;
              font-size: 20px;
              font-weight: 500;
              letter-spacing: 1px;

              small {
                font-size: 12px;
                position: relative;
                top: -5px;
              }
            }
          }

          &-logo {
            margin-top: 25px;
          }
        }
      }

      .copyright {
        color: #261a0f;
        font-size: 12px;
        font-weight: 500;

        /** Mobile. */
        @media (max-width: 1023px) {
          text-align: center;
          margin-bottom: 30px;
        }
      }

      .signature a {
        display: inline-block;
      }
    }
  }
}