/** @import: Vendor. */
@import "../vendor/normalize/normalize.css";
@import "../vendor/fancybox/jquery.fancybox.min.css";
@import "../vendor/owl/owl.carousel.min.css";

/** @import: Main. */
@import "reset";
@import "vars";
@import "fonts";

/** Body. */
body {
  font-size: 16px;
  font-family: 'DIN Pro';

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: .6;
      pointer-events: initial;
    }
  }
}

/** Layout. */
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  /** Mobile. */
  @media (max-width: 1300px) {
    padding: 0 25px;
  }
}

/** @import: Elements. */
@import "elements/general";
@import "elements/progress-screen";

/** @import: Modals. */
@import "modals/general";
@import "modals/cta";
@import "modals/success";
@import "modals/cookies";
@import "modals/policy";

/** @import: Pages. */
@import "pages/home";
