#main-cookies {
  background-color: white;
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 480px;
  z-index: 80;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);
  box-shadow: 0 5px 60px rgba(0, 0, 0, .3);

  /** Mobile. */
  @media (max-width: 767px) {
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: auto;
  }

  &.remove {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  .content {
    padding: 30px;

    /** Mobile. */
    @media (max-width: 767px) {
      padding: 20px 25px;
    }

    &-title {
      color: black;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 15px;
    }

    &-description {
      color: black;
      font-size: 16px;
      font-weight: normal;

      p {
        line-height: 1.6;
      }
    }

    &-cta {
      display: flex;
      align-items: center;
      margin-top: 30px;

      /** Mobile. */
      @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
      }

      button {
        background-color: #eeaa37;
        color: white;
        font-size: 16px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        height: 40px;

        &:not(:last-child) {
          margin-right: 30px;

          /** Mobile. */
          @media (max-width: 767px) {
            margin: 0 0 20px;
          }
        }
      }
    }
  }
}