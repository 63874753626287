@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Medium'), local('DINPro-Medium'),
       url('../assets/fonts/DINPro-Medium.woff2') format('woff2'),
       url('../assets/fonts/DINPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro'), local('DINPro'),
       url('../assets/fonts/DINPro.woff2') format('woff2'),
       url('../assets/fonts/DINPro.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Pro';
  src: local('DIN Pro Bold'), local('DINPro-Bold'),
       url('../assets/fonts/DINPro-Bold.woff2') format('woff2'),
       url('../assets/fonts/DINPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

